import { useEffect, useRef, useState } from 'react';
import incidentImage from '../../assets/images/incident-image.png';
import { scaleToPercentage } from '../../utils/common.util';
import CustomButton from '../CustomButton/CustomButtonComponent';
import SurveillanceCamera from '../SurveillanceCamera/SurveillanceCameraComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import './IncedentFeedAndLocationComponent.scss';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { AlertDetailsType } from '../../sdk/types/alert.type';
import WebRTCPlayer from '../WebRTCPlayer/WebRTCPlayer';

interface IIncedentFeedAndLocation {
    alertDetails: AlertDetailsType
}

const IncedentFeedAndLocation: React.FC<IIncedentFeedAndLocation> = ({ alertDetails }) => {
    const [maxContainerId, setMaxContainerId] = useState<string | null>(null);
    const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
    const figureRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (figureRef.current) {
            const updateDimensions = () => {
                if (figureRef?.current) {
                    const { clientWidth, clientHeight } = figureRef?.current;
                    setDimensions({ width: clientWidth, height: clientHeight });
                }
            };
            updateDimensions();
            window.addEventListener('resize', updateDimensions);

            return () => window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    const dynamicComponents = () => {
        const { coordinateX, coordinateY } = alertDetails?.camera || {} as CameraConfigType;
        const x = scaleToPercentage(coordinateX);
        const y = scaleToPercentage(coordinateY);
        console.log(x, y, alertDetails);
        return [
            {
                component: (
                    // <CameraView
                    //     svgIconName='surveillanceCamera'
                    //     cameraAngle={selectedCamera?.cameraAngle || 0}
                    //     fieldOfView={selectedCamera?.fieldOfView || 0}
                    //     showPin={false}
                    // />
                    <SurveillanceCamera />
                    // ), position: { x: '30%', y: '50%' }
                ), position: { x, y }
                // ), position: { x: (selectedCamera?.x_coordinate || 0)?.toString(), y: (selectedCamera?.y_coordinate || 0)?.toString() }
            },
        ]
    }

    const cameraZoneRenderer = (
        <ZoomablePage
            components={dynamicComponents()}
            showZoomControls={false}
            isImageDraggable={false}
            isCamCreationAllowed={false} />
    );

    const toggleMaximize = (containerName: string) => {
        setMaxContainerId(prev => prev === containerName ? null : containerName);
    }

    return (
        <section className="incident-feed-and-location">

            <ul className='container'>
                <li className={`item live-feed ${maxContainerId == 'live-feed' ? 'max' : ''}`}>
                    <section className="content">
                        <figure ref={figureRef}>
                            <WebRTCPlayer width={dimensions?.width} height={dimensions?.height} />
                        </figure>
                        {/* <footer className="actions">
                            <CustomButton
                                icon={<SvgIcon name={`${maxContainerId == 'live-feed' ? 'minimize' : 'maximize'}`} height={16} />}
                                onClick={() => toggleMaximize('live-feed')} />
                        </footer> */}
                    </section>
                </li>
                <li className='item recording'>
                    <section className="content">
                        <figure>
                            {/* <WebRTCPlayer /> */}
                            {/* <WebRTCPlayer width={dimensions?.width} height={dimensions?.height} /> */}
                            {/* <img src={incidentImage} /> */}
                            <video
                                src={alertDetails?.videos?.at(0)}
                                autoPlay
                                controls
                                muted
                                // loop
                                style={{ width: '100%', height: '100%' }}
                            />
                        </figure>
                    </section>
                </li>
            </ul>

            <ul className='container'>
                <li className='item nested'>
                    <section className="content">
                        {alertDetails?.images?.length && (
                            [alertDetails?.images?.slice(0, 2), alertDetails?.images?.slice(2, 4)].map((srcList: string[], iIndex: number) => (
                                <ol className='container inner-container' key={iIndex}>
                                    {
                                        srcList.map((dataSrc: string, jIndex: number) => (
                                            <li key={jIndex} className={`item incident-image${iIndex}${jIndex} ${maxContainerId == `incident-image${iIndex}${jIndex}` ? 'max' : ''}`}>
                                                <section className="content">
                                                    <figure>
                                                        <img
                                                            src={dataSrc}
                                                            alt={`Incident image ${iIndex}${jIndex}`}
                                                            onError={(e) => {
                                                                e.currentTarget.style.display = 'none';
                                                                const errorMessage = e.currentTarget.nextElementSibling;
                                                                const toggleMaxActionBtn = e.currentTarget?.parentElement?.nextElementSibling;
                                                                if (errorMessage) {
                                                                    (errorMessage as HTMLElement).style.display = 'flex';
                                                                    (toggleMaxActionBtn as HTMLElement).style.display = 'none';
                                                                }
                                                            }}
                                                        />
                                                        <span className="error-message">Image failed to load</span>
                                                    </figure>
                                                    <footer className="actions">
                                                        <CustomButton
                                                            icon={<SvgIcon name={`${maxContainerId == `incident-image${iIndex}${jIndex}` ? 'minimize' : 'maximize'}`} height={16} />}
                                                            onClick={() => toggleMaximize(`incident-image${iIndex}${jIndex}`)} />
                                                    </footer>
                                                </section>
                                            </li>
                                        ))
                                    }
                                </ol>
                            ))
                        )
                        }
                    </section>
                </li>
                <li className={`item cam-zone ${maxContainerId == 'cam-zone' ? 'max' : ''}`}>
                    <section className="content">
                        <div className="zone-container">
                            {cameraZoneRenderer}
                        </div>
                        <footer className="actions">
                            <CustomButton
                                icon={<SvgIcon name={`${maxContainerId == 'cam-zone' ? 'minimize' : 'maximize'}`} height={16} />}
                                onClick={() => toggleMaximize('cam-zone')} />
                        </footer>
                    </section>

                </li>
            </ul>

        </section>
    )
}

export default IncedentFeedAndLocation;