import React, { useState, useEffect } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import analyticsService from '../../sdk/services/analyticsService';
import CustomSelect from '../CustomSelect/CustomSelectComponent';

type AlertData = {
    alertType: string;
    // eventList: number[];
    eventCount: number[];
};

type ChartDataPoint = {
    time: string;
    [key: string]: number | string;
};

const timeRanges = [
    { label: 'Last 24 hours', step: 'hours', range: 23 },
    { label: 'Last 48 hours', step: 'hours', range: 47 },
    { label: 'Last 7 days', step: 'days', range: 6 },
    { label: 'Last 30 days', step: 'days', range: 29 },
    { label: 'Last 90 days', step: 'days', range: 89 },
];

const HorizontalStackedBarChart: React.FC = () => {
    const [selectedTimeRange, setSelectedTimeRange] = useState(timeRanges[0]);
    const [alertData, setAlertData] = useState<AlertData[]>([]);
    const [chartData, setChartData] = useState<ChartDataPoint[]>([]);

    useEffect(() => {
        const fetchAlertData = async () => {
            // refer and use epoch converter time: https://www.epochconverter.com/
            const endDate = Math.floor(Date.now() / 1000);
            // const endDate = Date.now();
            const t = 23 * 60;
            const startDate = endDate - (selectedTimeRange.range * (selectedTimeRange.step === 'hours' ? 3600 : 86400));

            // console.log(new Date(startDate), new Date(endDate));

            // Calculate the startDate based on the selected time range
            // const timeMultiplier = selectedTimeRange.step === 'hours' ? 3600 * 1000 : 86400 * 1000; // Convert to milliseconds
            // const startDateT = endDate - (selectedTimeRange.range * timeMultiplier); // Calculate the start date

            // console.log("Time Multiplier:", timeMultiplier);
            // console.log("Start T Date:", new Date(startDateT), startDateT);
            console.log("Start Date:", new Date(startDate), startDate);
            console.log("End Date:", new Date(endDate), endDate);

            try {
                const response = await analyticsService.getAlertData(startDate, endDate, (selectedTimeRange?.step as 'hours' | 'days'));
                let data = response as AlertData[];
                console.log(data);
                if (data) {

                }
                setAlertData(data);

                const times = Array.from({ length: data[0]?.eventCount.length || 0 }, (_, i) =>
                    // selectedTimeRange.step === 'hours' ? `${i * 4}h` : `Day ${i + 1}`
                    selectedTimeRange.step === 'hours' ? `${i + 1}h` : `Day ${i + 1}`
                );

                const formattedData = times.map((time, index) => {
                    const dataPoint: ChartDataPoint = { time };
                    data.forEach(alert => {
                        dataPoint[alert.alertType] = alert.eventCount[index] || 0; // Default to 0 if undefined
                    });
                    return dataPoint;
                });

                setChartData(formattedData);
            } catch (error) {
                console.error('Failed to fetch alert data', error);
            }
        };

        fetchAlertData();
    }, [selectedTimeRange]);

    const handleTimeRangeChange = (option: string) => {
        const selectedRange = timeRanges.find(range => range.label === option);
        if (selectedRange) {
            setSelectedTimeRange(selectedRange);
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                {/* <h3>Safety Metrics for {selectedTimeRange.label}</h3> */}
                <h3>Alerts</h3>
                <CustomSelect
                    options={timeRanges.map(range => range.label)}
                    selectedOption={selectedTimeRange.label}
                    onChange={handleTimeRangeChange}
                    placeholder="Select time range"
                    disabled={false} // Set to true to disable the select
                />
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    // width={500}
                    // height={300}
                    data={chartData}
                    margin={{ top: 24, right: 24, left: 24, bottom: 48 }}
                // margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                // margin={{ top: 20, right: 30, left: 100, bottom: 30 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 'dataMax']} />
                    <Tooltip />
                    <Legend />
                    {alertData.map(alert => (
                        <Bar key={alert.alertType}
                            dataKey={alert.alertType}
                            stackId="a"
                            fill={getColorForAlertType(alert.alertType)}
                            name={formateAlertTypeLabel(alert.alertType)}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

const formateAlertTypeLabel = (alertType: string) => {
    switch (alertType) {
        case 'required_accessories':
            return 'Required accessories';
        case 'restricted_accessories':
            return 'Restricted accessories';
        case 'occupancy_changed':
            return 'Occupancy changed';
        case 'occupancy_over':
            return 'Occupancy over';
        case 'occupancy_under':
            return 'Occupancy under';
        default:
            return alertType;
    }
}

const getColorForAlertType = (alertType: string) => {
    switch (alertType) {
        case 'PPE - No Hard Hat':
            return '#FFB366';
        case 'PPE - No Safety Vest':
            return '#FF66A3';
        case 'PPE - No Mask':
            return '#8080FF';
        case 'Fall Detection':
            return '#1CE4A4';
        case 'Proximity to running equipment':
            return '#31ACC9';
        case 'required_accessories':
            return '#FFB366';
        case 'restricted_accessories':
            return '#FF66A3';
        case 'occupancy_changed':
            return '#8080FF';
        case 'occupancy_over':
            return '#1CE4A4';
        case 'occupancy_under':
            return '#31ACC9';
        default:
            return '#ffc658';
    }
};

export default HorizontalStackedBarChart;
