import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './QuadrilateralOverlay.scss';

interface Point {
    coordinateX: number; // Percentage for internal usage (0.0 to 1.0)
    coordinateY: number; // Percentage for internal usage (0.0 to 1.0)
}

interface IQuadrilateralOverlayProps {
    mediaUrl: string;
    isVideo: boolean;
    showLines?: boolean;
    canDrag: boolean;
    initialPoints?: { coordinateX: string; coordinateY: string }[] | null; // Percentages as strings
    onPointsChange?: (points: { coordinateX: string; coordinateY: string }[]) => void; // Pass percentages as strings to the parent
}

export const defaultPoints: { coordinateX: string; coordinateY: string }[] = [
    { coordinateX: "0.1", coordinateY: "0.05" },
    { coordinateX: "0.3", coordinateY: "0.05" },
    { coordinateX: "0.3", coordinateY: "0.2" },
    { coordinateX: "0.1", coordinateY: "0.2" },
];

const FilledQuadrilateral = styled.div<{ points: Point[] }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
  clip-path: ${({ points }) =>
        `polygon(${points.map((point) => `${point.coordinateX * 100}% ${point.coordinateY * 100}%`).join(', ')})`};
  pointer-events: none;
`;

const QuadrilateralOverlay: React.FC<IQuadrilateralOverlayProps> = ({
    mediaUrl,
    isVideo,
    showLines = true,
    canDrag,
    initialPoints = null,
    onPointsChange,
}) => {
    const [points, setPoints] = useState<Point[]>(() => {
        if (initialPoints) {
            return initialPoints.map(point => ({
                coordinateX: parseFloat(point.coordinateX),
                coordinateY: parseFloat(point.coordinateY),
            }));
        }
        return defaultPoints.map(point => ({
            coordinateX: parseFloat(point.coordinateX),
            coordinateY: parseFloat(point.coordinateY),
        }));
    });
    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const [dragOffset, setDragOffset] = useState<{ coordinateX: number; coordinateY: number } | null>(null);

    const updateParentPoints = (updatedPoints: Point[]) => {
        const pointsAsPercentages = updatedPoints.map(point => ({
            coordinateX: point.coordinateX.toFixed(3),
            coordinateY: point.coordinateY.toFixed(3),
        }));

        if (onPointsChange) {
            onPointsChange(pointsAsPercentages);
        }
    };

    useEffect(() => {
        if (initialPoints) {
            const pointsInPercentage = initialPoints.map(point => ({
                coordinateX: parseFloat(point.coordinateX),
                coordinateY: parseFloat(point.coordinateY),
            }));
            setPoints(pointsInPercentage);
        }
    }, [initialPoints]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (canDrag && draggingIndex !== null && dragOffset) {
                const container = document.querySelector('.quadrilateral-overlay-container') as HTMLElement;
                const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

                const newCoordinateX = Math.max(0, (e.clientX - dragOffset.coordinateX) / containerWidth);
                const newCoordinateY = Math.max(0, (e.clientY - dragOffset.coordinateY) / containerHeight);

                const newPoints = [...points];
                newPoints[draggingIndex] = {
                    coordinateX: Math.min(1, newCoordinateX),
                    coordinateY: Math.min(1, newCoordinateY),
                };
                setPoints(newPoints);
            }
        };

        const handleMouseUp = () => {
            if (draggingIndex !== null) {
                setDraggingIndex(null);
                setDragOffset(null);
                document.body.style.cursor = 'default';

                // Update parent with percentage-based points
                updateParentPoints(points);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [canDrag, draggingIndex, points, dragOffset]);

    const handleMouseDown = (index: number) => (e: React.MouseEvent) => {
        if (canDrag) {
            e.preventDefault();
            const container = document.querySelector('.quadrilateral-overlay-container') as HTMLElement;
            const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
            const point = points[index];

            // Calculate the drag offset in pixels based on the current point's percentage
            setDragOffset({
                coordinateX: e.clientX - point.coordinateX * containerWidth,
                coordinateY: e.clientY - point.coordinateY * containerHeight,
            });

            setDraggingIndex(index);
            document.body.style.cursor = 'grabbing';
        }
    };

    const renderPoints = canDrag ? points?.map((point, index) => (
        <div
            key={index}
            className={`point ${canDrag && draggingIndex === index ? 'dragging' : ''}`}
            style={{
                left: `${point.coordinateX * 100}%`,
                top: `${point.coordinateY * 100}%`,
            }}
            onMouseDown={handleMouseDown(index)}
        />
    )) : null;

    return (
        <div className="quadrilateral-overlay-container">
            {isVideo ? (
                <video className="background-media" src={mediaUrl} autoPlay loop muted />
            ) : (
                <img className="background-media" src={mediaUrl} alt="Background" />
            )}
            {showLines && (
                <FilledQuadrilateral points={points} />
            )}
            {renderPoints}
        </div>
    );
};

export default QuadrilateralOverlay;
