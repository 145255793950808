import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { generateUUID } from "../../sdk/utils/utils";
import Toast from "./Toast";
import ToastService from "./ToastService";

export type ToastType = "success" | "warning" | "error" | "info";

interface IToastContext {
    open: (title: string, content: string, toastType: ToastType, duration?: number) => void;
}

interface IToastNotificationProps {
    children: ReactNode;
}

export interface IToastInfo {
    id: string;
    title: string;
    content: string;
    toastType: ToastType;
    duration: number;
}

const ToastContext = createContext<IToastContext | undefined>(undefined);

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastNotificationProvider");
    }
    return context;
};

const toastWrapperStyle: React.CSSProperties = {
    position: 'fixed',
    top: '16px',
    right: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: 9999,
};

export const ToastProvider: React.FC<IToastNotificationProps> = ({ children }) => {
    const [toasts, setToasts] = useState<IToastInfo[]>([]);

    const open = (title: string, content: string, toastType: ToastType, duration: number = 5000) => {
        setToasts((currentToasts) => [
            ...currentToasts,
            {
                id: generateUUID(),
                title,
                content,
                toastType,
                duration
            }
        ]);
    };

    const close = (id: string) => {
        setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
    };

    useEffect(() => {
        ToastService.setOpenFunction(open);
    }, [open]);

    const contextValue = useMemo(() => ({ open }), [open]);

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            {createPortal(
                <div className="toast-wrapper" style={toastWrapperStyle}>
                    {toasts.map((toast) => (
                        <Toast
                            key={toast.id}
                            id={toast.id}
                            title={toast.title}
                            content={toast.content}
                            toastType={toast.toastType}
                            duration={toast.duration}
                            close={() => close(toast.id)}
                        />
                    ))}
                </div>,
                document.body
            )}
        </ToastContext.Provider>
    );
};
