import React from 'react';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import './PinComponent.scss';

interface IPinProps {
    text: string;
    iconSize?: number;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Pin: React.FC<IPinProps> = ({ text, iconSize = 56, onClick }) => {

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
            onClick(event); // Pass the event to the onClick handler
        }
    };

    return (
        <div className="pin" onClick={handleClick}>
            <SvgIcon name='pin' width={iconSize} height={iconSize} />
            <figcaption>{text}</figcaption>
        </div>
    );
}

export default Pin;
