import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';
import { sortAlertsByAlertTimestamp } from "../../../utils/common.util";
import { AlertDetailsType } from "../../types/alert.type";
import { RootState } from "../store/store";

interface IAlertState {
    alertList: AlertDetailsType[];
}

const initialState: IAlertState = {
    alertList: [],
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlertList: (state, action: PayloadAction<AlertDetailsType[]>) => {
            let list = action.payload.filter(alert => !alert?.deleted);
            state.alertList = sortAlertsByAlertTimestamp(list);
        },
        updateAlert: (state, action: PayloadAction<{ id: string, updatedAlertResult: AlertDetailsType }>) => {
            const { id, updatedAlertResult } = action.payload;
            const index = state.alertList.findIndex(alert => alert.id == id);

            if (index !== -1) {
                state.alertList[index] = updatedAlertResult;
            }
        },
        deleteAlert: (state, action: PayloadAction<string>) => {
            state.alertList = state.alertList.filter(alert => alert.id !== action.payload);
        }
    }
});

const selectAlerts = (state: RootState) => state.alert.alertList;

export const selectResolvedAlerts = createSelector(
    [selectAlerts],
    (alerts) => alerts.filter((alert) => alert.resolved === true)
);

export const selectUnresolvedAlerts = createSelector(
    [selectAlerts],
    (alerts) => alerts.filter((alert) => alert.resolved === false)
);

export const selectAllAlerts = (state: RootState) => {
    return state.alert?.alertList;
}

export const selectAlertsByCameraId = (cameraId: string) => (state: RootState) => {
    return state?.alert?.alertList?.filter(alert => alert?.camera?.cameraId == cameraId);
}

export const selectLatestAlert = (cameraId: string) => (state: RootState) => {
    return state?.alert?.alertList?.find(alert => alert?.camera?.cameraId == cameraId);
}


export const selectLatestAlertByCameraId = (cameraId: string) => createSelector(
    (state: RootState) => state.alert.alertList,
    (alerts) => {
        // Filter alerts by cameraId
        const filteredAlerts = alerts.filter(alert => alert.camera.cameraId === cameraId);

        // Sort alerts by alertTimestamp in descending order
        const sortedAlerts = filteredAlerts.sort((a, b) => (b.alertTimestamp ?? 0) - (a.alertTimestamp ?? 0));

        // Return the latest alert, or undefined if no alerts are found
        return sortedAlerts[0];
    }
);

export const selectTotalAlertCountByCameraId = (cameraId: string) => createSelector(
    (state: RootState) => state.alert.alertList,
    (alerts: AlertDetailsType[]) => {
        // Filter alerts by cameraId
        const filteredAlerts = alerts.filter(alert => alert.camera.cameraId === cameraId);

        // Return the count of filtered alerts
        return filteredAlerts.length;
    }
);

export const { setAlertList, updateAlert, deleteAlert } = alertSlice.actions;
export default alertSlice.reducer;