import CustomCheckbox from '../CustomCheckbox/CustomCheckboxComponent';
import CustomSelect from '../CustomSelect/CustomSelectComponent';
import './ConfigActionComponent.scss';
import { useEffect, useState } from 'react';
import alertConfigService from '../../sdk/services/alertConfigService';
import { AlertConfigType } from '../../sdk/types/alertConfig.type';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { scaleToPercentage } from '../../utils/common.util';
import CustomAlertConfig from '../CustomAlertConfig/CustomAlertConfigComponent';
import SurveillanceCamera from '../SurveillanceCamera/SurveillanceCameraComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import ToastService from '../Toast/ToastService';

const listOptions: AlertConfigType[] = [
    {
        "id": "fb1b6f0403c943dfb177586b81657ce5",
        "alertName": "PPE - No Hard Hat",
        "alertType": "required_accessories",
        "enabled": false,
        "accessories": [
            "hardhats"
        ],
        "cameraFk": "e46768fdb57d47b596be27b6a191bc83",
        "cameraId": "6",
        "cameraName": "cam_6",
        "severity": "Severity Level 1",
        "createdBy": "Admin",
        "updatedBy": "Admin",
        "createdDate": 1724472279623,
        "lastModifiedDate": 1724472279623
    },
    {
        "id": "6032a4fccf5c4e598ce7888215f9ccc8",
        "alertName": "PPE - No Safety Vest",
        "alertType": "required_accessories",
        "enabled": false,
        "accessories": [
            "vest"
        ],
        "cameraFk": "e46768fdb57d47b596be27b6a191bc83",
        "cameraId": "6",
        "cameraName": "cam_6",
        "severity": "Severity Level 1",
        "createdBy": "Admin",
        "updatedBy": "Admin",
        "createdDate": 1724472279621,
        "lastModifiedDate": 1724472279621
    }
]

interface IConfigActionProps {
    selectedCamera: CameraConfigType | null;
}

const ConfigAction: React.FC<IConfigActionProps> = ({ selectedCamera }) => {
    const [safetyConfigList, setSafetyConfigList] = useState<AlertConfigType[]>([]);

    useEffect(() => {
        console.log(selectedCamera);
        const fetchSafetyConfigList = async () => {
            try {
                if (selectedCamera?.id) {
                    const list: AlertConfigType[] = await alertConfigService.getSafetyConfigList(selectedCamera?.id);
                    // const list: CameraConfigType[] = mockCameraList;
                    setSafetyConfigList(list);
                }
            } catch (error) {

            } finally {

            }
        }
        fetchSafetyConfigList();
    }, [selectedCamera]);

    const dynamicComponents = () => {
        const { coordinateX, coordinateY } = selectedCamera || {} as CameraConfigType;
        const x = scaleToPercentage(coordinateX);
        const y = scaleToPercentage(coordinateY);
        return [
            {
                component: (
                    <SurveillanceCamera />
                ), position: { x, y }
            },
        ]
    }

    const cameraZoneRenderer = (
        <aside className="camera-location">
            <header>Camera Location - {selectedCamera?.location}</header>
            <figure>
                <ZoomablePage
                    components={dynamicComponents()}
                    showZoomControls={false}
                    isImageDraggable={false}
                    isCamCreationAllowed={false} />
            </figure>
        </aside>
    );

    const handleUpdateFixedAlertEnable = async (enabled: boolean, config: AlertConfigType) => {
        console.log(enabled);
        try {
            const response = await alertConfigService.updateFixedAlertConfig(config.id, { enabled });
            if (response) {
                ToastService.showToast('Fixed alert update', `Successfully updated fixed alert`, 'success', 3000);
            }
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo || 'Something went wrong';
            ToastService.showToast('Fixed alert update', errorMessage, 'error');
        }
    }

    const modifyAlertRenderer = (
        <ul className="selection-list">
            {
                safetyConfigList?.map((config: AlertConfigType, index: number) => (
                    <li key={index}>
                        <article>
                            <CustomCheckbox checked={config?.enabled} onChange={(enabled) => handleUpdateFixedAlertEnable(enabled, config)} />
                            <span>{config.alertName}</span>
                        </article>
                        <CustomSelect
                            options={[config.severity]}
                            selectedOption={config.severity}
                            placeholder={'Select security level'}
                            onChange={(option: string): void => {
                                console.log(option)
                            }} />
                    </li>
                ))
            }
        </ul>
    );

    return (
        <section className="config-action">
            {
                selectedCamera?.id ? (
                    <>
                        <header className="config-action-header">
                            <label>Cam ID - {selectedCamera?.cameraId} - Configuration</label>
                        </header>

                        <article className="action-list-and-zone">
                            {modifyAlertRenderer}
                            {cameraZoneRenderer}
                        </article>

                        <CustomAlertConfig selectedCamera={selectedCamera} />
                    </>
                ) : (
                    <section className="no-camera-selected">
                        <p>No camera selected</p>
                    </section>
                )
            }
        </section >
    );
}

export default ConfigAction;