import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialogComponent';

interface DeleteAlertDialogProps {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    onSave: () => void;
    title: string;
    content: React.ReactNode;
    cancelText?: string;
    saveText?: string;
}

const DeleteAlertDialog: React.FC<DeleteAlertDialogProps> = ({ open, onClose, onCancel, onSave, title, content, saveText, cancelText }) => {
    return (
        <CustomDialog
            open={open}
            headerLabel={title}
            content={content}
            saveText={saveText}
            cancelText={cancelText}
            onClose={onClose}
            onCancel={onCancel}
            onSave={onSave}
        />
    );
};

export default DeleteAlertDialog;
