import React from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.scss';

interface IProps {
    appConfig?: any;
    userConfig?: any;
}

const App: React.FC<IProps> = ({ appConfig, userConfig }) => {
    return (
        <AppRoutes />
    );
}

export default App;
