import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import footerImg from "../../assets/svgs/login-footer-logo.svg";
import CustomButton from "../../components/CustomButton/CustomButtonComponent";
import SvgIcon from "../../components/SvgIcons/SvgIconComponent";
import ToastService from "../../components/Toast/ToastService";
import { useAuth } from "../../context/AuthContext";
import './LoginPage.scss';

const autoFeedLoginCreds = {
    // emailId: 'admin@email.com',
    emailId: 'kevin@sstech.com',
    password: 'smart123'
}

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();
    const { login } = useAuth();
    const dispatch = useDispatch();

    // Auto-fill login credentials in development mode
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'development') {
            setEmail(autoFeedLoginCreds.emailId);
            setPassword(autoFeedLoginCreds.password);
        }
    }, []);

    const handleLogin = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // const hashedPassword = hashPassword(password);
        const hashedPassword = password;
        try {
            const result = await login(email, hashedPassword);
            if (result) {
                ToastService.showToast('Login success', `Welcome ${result?.firstName} ${result?.lastName}`, 'success', 3000);
                navigate('/alerts');
            }
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo ? 'Invalid email or password' : 'Something went wrong';
            ToastService.showToast('Login failed', errorMessage, 'error');
        }
    }, [email, password, login, navigate]);

    const handleInputBoxValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'emailId') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    }

    const renderInputBox = (labelName: string, inputType: 'text' | 'password', placeholderTxt: string, inputValue: string, name: string, iconName: 'at' | 'lock') => (
        <article className="input-and-label">
            <label>{labelName}</label>

            <div className="input-box">
                <input
                    className="inputBox"
                    type={inputType}
                    placeholder={placeholderTxt}
                    value={inputValue}
                    name={name}
                    onChange={(e) => handleInputBoxValueChange(e)}
                    autoComplete="off"
                />
                <figure className="icon">
                    <SvgIcon name={iconName} width={16} height={16} />
                </figure>
            </div>
        </article>
    );

    const isFormValid = email.trim() !== '' && password.trim() !== '';

    return (
        <section className="login-page">

            <form onSubmit={handleLogin}>
                <header>
                    <figure>
                        <SvgIcon name="logoAndName" height={32} />
                    </figure>
                    <label></label>
                </header>

                <div className="content">
                    {renderInputBox('Your email', 'text', 'Enter email id', email, 'emailId', 'at')}
                    {renderInputBox('Password', 'password', 'Enter password', password, 'password', 'lock')}
                    <CustomButton
                        className="submit-btn"
                        text="Login"
                        type="submit"
                        disabled={!isFormValid}
                    />
                </div>

            </form>

            <footer className="login-footer-img">
                <img src={footerImg} alt="" />
            </footer>

        </section >
    );
}

export default LoginPage;