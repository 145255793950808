import React, { useEffect } from "react";
import CustomButton from "../CustomButton/CustomButtonComponent";
import SvgIcon from "../SvgIcons/SvgIconComponent";
import { IToastInfo } from "./ToastProvider";
import './Toast.scss';

interface IToast extends IToastInfo {
    close: () => void;
}

const Toast: React.FC<IToast> = ({
    title = '',
    content = '',
    toastType = 'info',
    duration = 5000,
    close
}) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            close();
        }, duration);

        return () => clearTimeout(timer);
    }, [close, duration]);

    const getSvgIconToastTypeName = (toastType === 'success' ? 'roundTickmark' : (toastType === 'info' ? 'roundInfo' : (toastType === 'warning' ? 'roundWarning' : 'roundCross')));

    return (
        <section className={`toaster ${toastType}`}>
            <header>
                <figure>
                    <SvgIcon name={getSvgIconToastTypeName} />
                    <figcaption>{title}</figcaption>
                </figure>
                <CustomButton icon={<SvgIcon name="close" />} onClick={close} />
            </header>
            <p>{content}</p>
        </section>
    );
}

export default Toast;
