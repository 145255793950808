import HorizontalStackedBarChart from "../../components/HorizontalStackedBarChart/HorizontalStackedBarChart";
import VerticalStackedBarChart from "../../components/VerticalStackedBarChart/VerticalStackedBarChart";
import "./AnalyticsPage.scss";

const AnalyticsPage: React.FC = () => {
    return (
        <section className="analytics-page">
            {/* Analytics page */}

            <div className="char-container">
                <HorizontalStackedBarChart />
            </div>
            {/* <VerticalStackedBarChart /> */}

        </section>
    );
}

export default AnalyticsPage;
