import { useEffect, useRef, useState } from 'react';
import './SearchBoxComponent.scss';

interface ISearchBoxProps {
    placeHolderTxt: string;
    onSearch: (query: string) => void;
    onOutsideClick: () => void;
}

const SearchBox: React.FC<ISearchBoxProps> = ({ placeHolderTxt, onSearch, onOutsideClick }) => {
    const [query, setQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const searchBoxRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e?.target?.value?.trim();
        setQuery(newQuery);
        onSearch(newQuery);
    };

    const handleSearch = () => {
        if (query.trim()) {
            onSearch(query);
        }
    };

    const handleClear = () => {
        setQuery('');
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
                if (query.trim() === '') {
                    onOutsideClick(); // Call the event if the content is empty
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [query, onOutsideClick]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <section
            ref={searchBoxRef}
            className={`search-box ${(isFocused || query) ? 'focused' : ''}`}
            onClick={(e) => e.stopPropagation()}>

            {/* <div className="input-wrapper"> */}
            <input
                ref={inputRef}
                type="search"
                name=""
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder={placeHolderTxt}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {/* <CustomButton
                    icon={<SvgIcon name='close' width={16} height={16} />}
                    variant='contained'
                    disabled={!query}
                    onClick={() => console.log(true)}
                /> */}
            {/* </div> */}

            {/* <CustomButton icon={<SvgIcon name='search' width={16} height={16} />} variant='contained' onClick={() => console.log(true)} /> */}
        </section>
    );
}

export default SearchBox;