// Define environment-specific configurations
type EnvName = 'development' | 'production' | 'staging';

interface IEnvConfig {
    apiUrl: string;
    mockUrl: string;
    env: EnvName;
    endPoints: { [key: string]: string }
}

const endPoints: { [key: string]: any } = {
    auth: {
        categoryUrl: 'admin/user',
        points: {
            login: '/login',
            logout: '/logout'
        }
    },
    cameraConfig: {
        categoryUrl: 'config/camera',
        points: {
            add: '',
            getAll: '',
            getById: '/{id}',
            getByIdAndAlertId: '/{id}/alert/{alertId}',
            delete: '/{id}',
        }
    },
    customAlertConfig: {
        categoryUrl: 'config/custom-alert',
        points: {
            getAllCustomAlertConfig: '/camera/{id}',
            addCustomAlertConfig: '/camera/{id}/all',
            updatedCustomAlertConfig: '/{alertId}'
        }
    },
    alertConfig: {
        categoryUrl: 'config/alert',
        points: {
            fixedAlertConfig: '/camera/{id}',
            updateFixedAlertConfig: '/{alertId}',
            addAlertConfig: '/camera/{id}'
        }
    },
    alert: {
        categoryUrl: 'dashboard',
        points: {
            getAlertBannerCountList: '/alerttype',
            getAllAlertsForCameraById: '/alert/{id}', // '/camera/{id}'
            getAllAlerts: '/alert', // this will be changed to '/alert/all'
            getAllCameraDetailsForFloorMap: '/camera',
            getAlertByCameraId: '/alert/camera/{cameraId}',
            updateAlert: '/alert/{alertId}',
        }
    },
    analytics: {
        categoryUrl: 'dashboard',
        points: {
            getAlertsData: '/analytics/alert'
        }
    }
};


const devConfig: IEnvConfig = {
    apiUrl: 'https://dev.workersafety.aiotdemos.com/api/v1/',
    mockUrl: 'http://localhost:5000/',
    env: 'development',
    endPoints: endPoints,
};

const prodConfig: IEnvConfig = {
    apiUrl: 'https://demo.workersafety.aiotdemos.com/api/v1/',
    mockUrl: 'http://localhost:5000/',
    env: 'production',
    endPoints: endPoints,
};

const stageConfig: IEnvConfig = {
    apiUrl: 'https://test.workersafety.aiotdemos.com/api/v1/',
    mockUrl: 'http://localhost:5000/',
    env: 'staging',
    endPoints: endPoints,
};



// Define a type for the allowed environment names

// Create a configuration map
const envConfigDetails: Record<EnvName, IEnvConfig> = {
    development: devConfig,
    production: prodConfig,
    staging: stageConfig,
};

// Safely get the current environment with a fallback
const currentEnv: EnvName = (process.env.REACT_APP_ENV as EnvName) ?? 'development';

// Export the configuration based on the current environment
const envConfig: IEnvConfig = envConfigDetails[currentEnv];

export default envConfig;
