import { Notifications } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import AlertBox from "../AlertBox/AlertBoxComponent";
import CustomButton from "../CustomButton/CustomButtonComponent";
import SvgIcon from "../SvgIcons/SvgIconComponent";
import './ExpandableAlertList.scss';
import { AlertDetailsType } from "../../sdk/types/alert.type";
import { formatTimestamp } from "../../utils/common.util";
import SearchBox from "../SearchBox/SearchBoxComponent";

// Define a type for the list item props
interface ListItem {
    id: number;
    title: string;
    content: React.ReactNode;
}

// Define the component props
interface ExpandableListProps {
    setFilterBtnClassName?: 'all' | 'resolved' | 'unresolved';
    items: AlertDetailsType[];
    showHeader?: boolean;
    showViewDetailsBtn?: boolean;
    defaultExpandedAlertId?: string;
    showMediaThumbnail?: boolean;
    alertToShowViewDetails: AlertDetailsType | null;
    showStatus: boolean;
    showInPopupDesign: boolean;
    severityLevel?: string;
    onSelectingAlertToShowAction: (alert: AlertDetailsType) => void;
    onAlertViewDetailsClick: (selectedAlert: AlertDetailsType) => void;
    onResolveBtnClick: (selectedAlert: AlertDetailsType) => void;
    onDeleteBtnClick: (selectedAlert: AlertDetailsType) => void;
    onToggleResolveIconClick?: () => void;
    onImageThumbnailClick: (selectedAlert: AlertDetailsType) => void;
    onVideoThumbnailClick: (selectedAlert: AlertDetailsType) => void;
}

const ExpandableAlertList: React.FC<ExpandableListProps> = ({
    setFilterBtnClassName = 'all',
    items,
    showHeader = true,
    defaultExpandedAlertId = '',
    showViewDetailsBtn = true,
    showMediaThumbnail = true,
    alertToShowViewDetails = null,
    showInPopupDesign = true,
    showStatus = true,
    severityLevel = '',
    onSelectingAlertToShowAction,
    onAlertViewDetailsClick,
    onResolveBtnClick,
    onDeleteBtnClick,
    onToggleResolveIconClick,
    onImageThumbnailClick,
    onVideoThumbnailClick,
}) => {
    const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // Create a ref object to store references to list items
    const itemRefs = useRef<Record<string, HTMLLIElement | null>>({});

    const handleClick = (alert: AlertDetailsType) => {
        onSelectingAlertToShowAction(alert);
    };

    // Effect to scroll to the expanded item if showViewDetailsBtn is true
    useEffect(() => {
        if (alertToShowViewDetails && showViewDetailsBtn) {
            const element = itemRefs.current[alertToShowViewDetails.id];
            if (element) {
                // Add a slight delay to ensure everything is rendered
                setTimeout(() => {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center', // Center the item vertically
                        inline: 'nearest' // Align the item horizontally
                    });
                }, 100); // Adjust delay as needed
            }
        }
    }, [alertToShowViewDetails, showViewDetailsBtn]);

    const filteredList = items.filter(
        (alert) =>
            alert?.alertName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            alert?.camera?.cameraName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            alert?.camera?.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            alert?.camera?.cameraId?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <section className="expandable-alert-list">

            {
                showHeader && (
                    <header className={`list-header ${showSearchBox ? 'show-search' : ''}`}>

                        {
                            !showSearchBox && (
                                <>
                                    <div>
                                        <h4>Alerts</h4>
                                    </div>

                                    <aside className="actions">
                                        <CustomButton className={setFilterBtnClassName} icon={<SvgIcon name='reset' width={16} height={16} />} variant='contained' onClick={onToggleResolveIconClick} />
                                        <CustomButton icon={<SvgIcon name='search' width={16} height={16} />} variant='contained' onClick={() => setShowSearchBox(true)} />
                                    </aside>
                                </>
                            )
                        }
                        {
                            showSearchBox && (
                                <SearchBox
                                    placeHolderTxt="Search by alert name & cam ID/Name/location"
                                    onSearch={(query) => setSearchQuery(query)}
                                    onOutsideClick={() => setShowSearchBox(false)} />
                            )
                        }
                    </header>
                )
            }

            <aside className={`list-content ${!filteredList?.length ? 'no-alerts' : ''}`}>
                <ul>
                    {filteredList?.map((item: AlertDetailsType) => (
                        <li
                            key={item.id}
                            ref={(el) => itemRefs.current[item.id] = el} // Set ref for each list item
                            onClick={() => handleClick(item)}
                            className={(alertToShowViewDetails?.id === item?.id) ? 'selected' : ''}
                        >
                            <AlertBox
                                headerLabel={item?.alertName}
                                subHeaderLabel={item?.camera?.location}
                                subHeaderIcon={<SvgIcon name='location' />}
                                footerLabel={item?.camera?.cameraId}
                                // timestamp="07-22-2024 | 12:09 AM"
                                timestamp={formatTimestamp(item?.alertTimestamp || 0)}
                                showActionSection={alertToShowViewDetails?.id === item.id} // Show action section if this is the expanded item
                                showViewDetailsBtn={showViewDetailsBtn}
                                isAlertResolved={item?.resolved}
                                showInPopupDesign={showInPopupDesign}
                                showStatus={showStatus}
                                severityLevel={severityLevel}
                                showMediaThumbnail={showMediaThumbnail}
                                // thumbnails={{
                                //     image:
                                //         "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                //     video:
                                //         "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                // }}
                                thumbnails={{
                                    image: item?.images[0] || '',
                                    video: item?.images[0] || '',
                                    // video:
                                    //     "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
                                }}
                                onAlertViewDetailsClick={() => onAlertViewDetailsClick(item)}
                                onResolveBtnClick={() => onResolveBtnClick(item)}
                                onDeleteBtnClick={() => onDeleteBtnClick(item)}
                                onImageThumbnailClick={() => onImageThumbnailClick(item)}
                                onVideoThumbnailClick={() => onVideoThumbnailClick(item)}
                            />
                        </li>
                    ))}
                </ul>

                {
                    !filteredList?.length && (
                        <section className="no-alert-block">
                            <p>No alert to display</p>
                        </section>
                    )
                }

            </aside>
        </section>
    );
};

export default ExpandableAlertList;
