import { ToastType, useToast } from './ToastProvider';

class ToastService {
    private static open: (title: string, content: string, toastType: ToastType, duration?: number) => void;

    public static setOpenFunction(openFunc: (title: string, content: string, toastType: ToastType, duration?: number) => void) {
        this.open = openFunc;
    }

    public static showToast(title: string, content: string, toastType: ToastType, duration?: number) {
        if (this.open) {
            this.open(title, content, toastType, duration);
        }
    }
}

export default ToastService;
