import { MouseEvent, useEffect, useRef, useState } from 'react';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import AddCameraDetails, { AddCameraDetailsHandle, FormFieldsType } from '../AddCameraDetails/AddCameraDetailsComponent';
import CustomButton from '../CustomButton/CustomButtonComponent';
import CustomDialog from '../CustomDialog/CustomDialogComponent';
import useCustomDialogHandler, { IDialogProps } from '../CustomDialog/useCustomDialogHandler';
import RippleEffect from '../RippleEffect/RippleEffect';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import './CameraListComponent.scss';
import SearchBox from '../SearchBox/SearchBoxComponent';

interface ICameraList {
    list: CameraConfigType[];
    selectedCamera: CameraConfigType | null;
    updateSelectedCamera: (selected: CameraConfigType) => void;
    deleteCamera: (id: string) => void;
    onCameraAddedEvent: (data: CameraConfigType) => void;
}

const CameraList: React.FC<ICameraList> = ({ list, selectedCamera, updateSelectedCamera, deleteCamera, onCameraAddedEvent }) => {
    const { dialogData, openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog, setDialogData, handleSetDialogData } = useCustomDialogHandler();
    // const [cameraToDelete, setCameraToDelete] = useState<CameraConfigType | null>(null);
    const [dialogProps, setDialogProps] = useState<IDialogProps | null>(null);
    const [addCameraFormData, setAddCameraFormData] = useState<FormFieldsType | null>(null);
    const [isAddCameraFormValid, setIsAddCameraFormValid] = useState<boolean>(false);
    const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const addCameraDetailsRef = useRef<AddCameraDetailsHandle>(null);

    useEffect(() => {
        console.log('isAddCameraFormValid::', isAddCameraFormValid);
        // if (isAddCameraFormValid) {
        updateAddCameraDialogProps();
        // }
    }, [isAddCameraFormValid]);

    const updateAddCameraDialogProps = () => {
        return setDialogProps({
            title: 'Add camera',
            content: addCameraDialogContent,
            dialogMaxWidth: 'lg',
            isSaveDisabled: !isAddCameraFormValid,
            cancelText: 'Cancel',
            saveText: 'Save',
            onSave: (data: any) => {
                handleAddCamera();
                handleCloseDialog();
            },
            onCancel: handleCloseDialog
        });
    };

    const handleListItemClick = (event: MouseEvent<HTMLElement>, cam: CameraConfigType) => {
        event.stopPropagation();
        console.log('selected camera ::', cam);
        updateSelectedCamera(cam);
    }

    const handleListItemDeleteBtnClick = (event: MouseEvent<HTMLButtonElement>, camConfig: CameraConfigType) => {
        event.stopPropagation();
        console.log('selected camConfig ::', camConfig);
        setDialogProps({
            title: 'Delete camera',
            content: deleteCameraDialogContent(camConfig),
            dialogMaxWidth: 'xs',
            cancelText: 'Cancel',
            saveText: 'Delete',
            isSaveDisabled: false,
            onSave: (data: any) => {
                if (camConfig?.id) {
                    deleteCamera(camConfig?.id);
                }
                handleDialogDeleteCameraClick();
            },
            onCancel: handleCloseDialog
        })
        // setCameraToDelete(camConfig);
        handleOpenDialog();
    }

    const handleAddCameraBtnClick = () => {
        updateAddCameraDialogProps();
        handleOpenDialog();
    }

    const handleAddCamera = () => {
        if (addCameraDetailsRef.current) {
            addCameraDetailsRef.current.save();
        }
    }

    const handleDialogDeleteCameraClick = () => {
        // setCameraToDelete(null);
        handleCloseDialog();
    }

    const deleteCameraDialogContent = (cameraToDelete: CameraConfigType): JSX.Element => {
        return (
            <section className="delete-cam-dialog-content">
                <header>Are you sure you want to delete #{cameraToDelete?.cameraId} camera? </header>
                <p>Note: All the incidents associated with #{cameraToDelete?.cameraId} camera will be deleted</p>
            </section>
        );
    };

    const handleAddCameraDetailsFormChange = (data: FormFieldsType) => {
        setDialogData(data);
        setAddCameraFormData(data);
        handleSetDialogData(data);
    }

    const addCameraDialogContent: JSX.Element = (
        <AddCameraDetails
            ref={addCameraDetailsRef}
            onFormChange={handleAddCameraDetailsFormChange}
            onCameraAddedEvent={onCameraAddedEvent}
            formValidationResultEvent={setIsAddCameraFormValid} />
    );

    const customDialogRenderer = () => {
        if (!dialogProps) return null;
        return (
            <CustomDialog
                headerLabel={dialogProps.title}
                open={openDialog}
                content={dialogProps.content}
                dialogMaxWidth={dialogProps?.dialogMaxWidth || 'md'}
                cancelText={dialogProps.cancelText}
                saveText={dialogProps.saveText}
                isSaveDisabled={dialogProps?.isSaveDisabled}
                onClose={handleCloseDialog}
                onCancel={dialogProps.onCancel}
                onSave={dialogProps.onSave}
            />
        )
    }

    const filteredList = list.filter(
        (cam) =>
            cam.cameraName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            cam.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            cam.cameraId?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <section className="camera-list-container">

                <header className={`list-header ${showSearchBox ? 'show-search' : ''}`}>
                    {
                        !showSearchBox && (
                            <>
                                <div>
                                    <h4>{list?.length}</h4>
                                    <h4>Fixed camera</h4>
                                </div>

                                <aside className="actions">
                                    <CustomButton icon={<SvgIcon name='plus' width={16} height={16} />} variant='contained' onClick={() => handleAddCameraBtnClick()} />
                                    <CustomButton icon={<SvgIcon name='search' width={16} height={16} />} variant='contained' onClick={() => setShowSearchBox(true)} />
                                </aside>
                            </>
                        )
                    }
                    {
                        showSearchBox && (
                            <SearchBox
                                placeHolderTxt="Search by Camera ID/Name/Location"
                                onSearch={(query) => setSearchQuery(query)}
                                onOutsideClick={() => setShowSearchBox(false)} />
                        )
                    }
                </header>

                <aside className="list-content">
                    {
                        filteredList?.length ? (
                            <ul className="list">
                                {
                                    filteredList.map((cam: CameraConfigType, index: number) => (
                                        <RippleEffect as="li" key={cam.id} className={`item ripple-list-item ${selectedCamera?.id === cam?.id ? 'selected' : ''}`} onClick={(e: MouseEvent<HTMLElement>) => handleListItemClick(e, cam)}>
                                            <label title={cam.cameraId}>{cam.cameraName}</label>
                                            <CustomButton icon={<SvgIcon name='delete' width={16} height={16} />} variant='outlined' onClick={(e: MouseEvent<HTMLButtonElement>) => handleListItemDeleteBtnClick(e, cam)} />
                                        </RippleEffect>
                                    ))
                                }
                            </ul>
                        ) : (
                            <div className="no-list-items">
                                <p>No cameras to show.</p>
                                {
                                    !list?.length && (
                                        <RippleEffect as="div">
                                            <CustomButton text='Add camera' variant='contained' onClick={() => handleAddCameraBtnClick()} />
                                        </RippleEffect>
                                    )
                                }
                            </div>
                        )
                    }

                </aside>

            </section>
            {customDialogRenderer()}
        </>
    );
}

export default CameraList;