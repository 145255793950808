import envConfig from "../../EnvConfig";
import apiService from "../core/apiService";
import { validateEndpoint } from "../utils/utils";

interface AlertData {
    alertType: string;
    // eventList: number[];
    eventCount: number[];
}

export const mockAlertData = [
    // {
    //     alertType: "PPE - No Hard Hat",
    //     eventCount: [10, 20, 5, 3, 15]
    // },
    {
        alertType: "PPE - No Safety Vest",
        eventCount: [30, 40, 10, 8, 12]
    },
    {
        alertType: "PPE - No Mask",
        eventCount: [25, 50, 15, 9, 7]
    },
    // {
    //     alertType: "Fall Detection",
    //     eventCount: [12, 60, 20, 15, 10]
    // },
    // {
    //     alertType: "Proximity to running equipment",
    //     eventCount: [18, 80, 25, 10, 5]
    // },
];

class AnalyticsService {

    private endPoints: { [key: string]: any };
    private endPointCategory: string;

    constructor() {
        this.endPoints = envConfig?.endPoints || {};
        this.endPointCategory = 'analytics';
    }

    public async getAlertData(startDate: number, endDate: number, step: 'hours' | 'days'): Promise<AlertData[]> {
        try {
            // const url = `api/v1/analytics/alert?startDate=${startDate}&endDate=${endDate}&step=${step}`;
            let url = validateEndpoint(this.endPoints, this.endPointCategory, 'getAlertsData');
            url = `${url}?startDate=${startDate}&endDate=${endDate}&step=${step}`;
            return await apiService.get(url);
            // return mockAlertData;
        } catch (error) {
            console.error('Error getting alert data:', error);
            throw error;
        }

        // Simulating with a delay and mock data. And network latency
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        // return mockAlertData;
    }

}

// Export a singleton instance of the AuthService class
const analyticsService = new AnalyticsService();
export default analyticsService;